@import '../../../assets/variables';

.__Stepper {
  background: #becad4;
  display: flex;
  height: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  .__Stepper-item-disabled {
    background-color: #becad4 !important;
    &::before {
      border: none !important;
    }
    &::after {
      border: none !important;
    }
  }
  .__Stepper-item {
    cursor: pointer;
    background-color: $brand-color;
    border-color: $brand-color;
    color: white;
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
    font-family: $global-font;
    font-style: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    svg {
      width: 14px;
      margin-right: 10px;
    }
    &::after {
      display: block;
      content: '';
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 12px solid;
      border-left-color: #fff;
      position: absolute;
      z-index: 1;
      top: -4px;
      right: -12px;
    }
    &::before {
      display: block;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 10px solid;
      border-left-color: inherit;
      content: '';
      position: absolute;
      right: -10px;
      top: 0;
      z-index: 2;
    }
  }
}
