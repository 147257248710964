.priceListItem {
  ul {
    padding: 0;
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p {
        font-family: $global-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.accordion-item {
  position: absolute;
  top: 12px;
  right: 15px;
  color: $grey;

  svg {
    width: 17px;
  }
}

.nested-accordion-btn {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $black;
}

.price {
  align-self: center;
  font-family: Inconsolata;
  font-weight: 700;
  font-size: 15px;
  color: $black;
}

.no-support_types {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 17px;
    p {
      font-family: $global-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
}

.BadgeBox-gold {
  background: #e58e26;
}
.BadgeBox-silber {
  background: #4b6584;
}
.BadgeBox-diamant {
  background: #474787;
}
.BadgeBox {
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    max-width: 100px;
    margin: 10px;
  }
  div {
    margin-left: 10px;
    span {
      color: white;
      text-transform: capitalize;
    }
    h3 {
      color: white;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .BadgeBox-bg {
    margin: 0;
    position: absolute;
    width: 200px;
    bottom: 0;
    right: 0;
  }
}
