@import '../../assets/variables';

.Page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	align-items: center;

	&__wrapper {
		width: 40vw;

		@media screen and (max-width: $break-point) {
			width: 100%;
		}

		@media screen and (min-width: $break-point-xl) {
			width: 30vw;
		}
	}
}
